import React from 'react';
import AllRoutes from './routes/AllRoutes';
import "react-toastify/dist/ReactToastify.css";
import GoogleAnalytics from './Components/GoogleAnalytics/GoogleAnalytics';
const App = () => {
  return (
    <>
      <GoogleAnalytics />
      <AllRoutes />
    </>
  );
};

export default App;

