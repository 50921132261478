import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TRACKING_ID = "G-CFH3D2YJHC";

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`;
    script.async = true;
    document.head.appendChild(script);
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      window.gtag = gtag;
      window.gtag('js', new Date());
      window.gtag('config', TRACKING_ID);
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname + window.location.search,
      });
    };
  }, []);

  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
};

export default GoogleAnalytics;
